import api_link from "../configs/api_routes";
import axios from "axios";

let counter_failed_request = 0;

async function refresh_access_token() {
  counter_failed_request++;
  if(counter_failed_request >= 10){
    return null
  }
  try {
    const refresh_token = localStorage.getItem("refresh_token");
    const response = await axios.post(`${api_link.base_url}/new_access_token`, { refresh_token });
    localStorage.setItem("token", response.data.token);
    localStorage.setItem("refresh_token", response.data.refresh_token);
    return true;
  } catch (e) {
    localStorage.removeItem("token")
    return null;
  }
}

async function get_refresh_token() {
  counter_failed_request++;
  if(counter_failed_request >= 10){
    return null
  }
  try {
    const refresh_token = localStorage.getItem("refresh_token");
    const response = await axios.post(`${api_link.base_url}/new_refresh_token`, { refresh_token });
    localStorage.setItem("token", response.data.token);
    localStorage.setItem("refresh_token", response.data.refresh_token);
    return true;
  } catch (e) {
    localStorage.removeItem("refresh_token")
    return null;
  }
}
export { refresh_access_token, get_refresh_token };
