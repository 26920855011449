import React from "react";
import axios from "axios";
import api_link from "../configs/api_routes";
import { get_refresh_token, refresh_access_token } from "../helpers/tokens";

import { version } from "../../../package.json";
const REQUEST_HEADER = { headers: { "x-front-version": version } };
const ControlsStateContext = React.createContext();
const ControlsDispatchContext = React.createContext();

async function get_api_infos(dispatch) {
  dispatch({ type: "request_init" });
  try {
    const response = await axios.get(`${api_link.base_url}`, REQUEST_HEADER);
    dispatch({ type: "request_api_infos_success", payload: response.data });
    return { success: true };
  } catch (error) {
    if (!error.response || !error.response.status) {
      dispatch({ type: "error", payload: error.message });
      return { success: false, message: error.message };
    }
    let message = error.response.data.message;
    dispatch({ type: "error", payload: message });
    return { success: false, message: message };
  }
}

async function loginWithCredentials(dispatch, { email, password }) {
  dispatch({ type: "request_login_init" });
  try {
    const response = await axios.post(`${api_link.base_url}/login`, { email, password }, REQUEST_HEADER);
    // console.log(response);
    dispatch({ type: "request_login_success", payload: response.data });
    localStorage.setItem("token", response.data.token);
    localStorage.setItem("refresh_token", response.data.refresh_token);
    return { success: true };
  } catch (error) {
    let message = "Email and/or password incorrects.";
    //  error.response.data.message ||
    dispatch({ type: "error", payload: message });
    return { success: false, message: message };
  }
}

/**
 *
 * @param {*} dispatch
 * @param {String} token
 */
async function auto_login(dispatch, refresh_token = localStorage.getItem("refresh_token")) {
  dispatch({ type: "request_login_init" });
  try {
    const response = await axios.post(`${api_link.base_url}/new_access_token`, { refresh_token }, REQUEST_HEADER);
    dispatch({ type: "request_login_success", payload: response.data });
    localStorage.setItem("token", response.data.token);
    localStorage.setItem("refresh_token", response.data.refresh_token);
    return { success: true };
  } catch (error) {
    if (!error.response || !error.response.status) {
      dispatch({ type: "error", payload: error.message });
      return { success: false, message: error.message };
    }
    let access_token_refreshed = undefined;
    if (error.response.status === 401) {
      // console.log("access");
      access_token_refreshed = await refresh_access_token();
      if (!access_token_refreshed) {
        // console.log("refresh");
        const refresh_token_refreshed = await get_refresh_token();
        if (!refresh_token_refreshed) {
          window.location.reload();
        }
      }
      auto_login(dispatch, localStorage.getItem("refresh_token"));
      return;
    }
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    if (error.response.status === 500) window.location.reload();
  }
}

/**
 *
 * @param {*} dispatch
 */
async function logout(dispatch, email) {
  dispatch({ type: "request_logout_init" });
  try {
    await axios.post(`${api_link.base_url}/logout`, { email }, REQUEST_HEADER);
    dispatch({ type: "request_logout_success" });
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("user");
    localStorage.removeItem("preferences");
    localStorage.removeItem("last_customer");
    return { success: true };
  } catch (error) {
    if (!error.response || !error.response.status) {
      dispatch({ type: "error", payload: error.message });
      return { success: false, message: error.message };
    }
    let message = error?.response?.data?.message;
    dispatch({ type: "error", payload: message });
    return { success: false, message: message };
  }
}

/**
 *
 * @param {*} dispatch
 */
async function password_forgotten(dispatch, email) {
  dispatch({ type: "request_init" });
  try {
    await axios.post(`${api_link.base_url}/password/forgotten`, { email }, REQUEST_HEADER);
    dispatch({ type: "request_password_forgotten_success" });
    return { success: true, message: "We've sent a password reset link at " + email };
  } catch (error) {
    if (!error.response || !error.response.status) {
      dispatch({ type: "error", payload: error.message });
      return { success: false, message: error.message };
    }
    let message = error?.response?.data?.message;
    dispatch({ type: "error", payload: message });
    return { success: false, message: message };
  }
}

/**
 *
 * @param {*} dispatch
 */
async function password_reset(dispatch, { password, token = localStorage.getItem("token") }) {
  dispatch({ type: "request_init" });
  try {
    await axios.post(
      `${api_link.base_url}/password/reset`,
      { password },
      { headers: { ...REQUEST_HEADER.headers, Authorization: "Bearer " + token } }
    );
    dispatch({ type: "request_password_forgotten_success" });
    return { success: true, message: "password reset with success " };
  } catch (error) {
    if (!error.response || !error.response.status) {
      dispatch({ type: "error", payload: error.message });
      return { success: false, message: error.message };
    }
    let message = "Something went wrong when reset password : " + error?.response?.data?.message;
    dispatch({ type: "error", payload: message });
    return { success: false, message: message };
  }
}

function ControlsReducer(state, action) {
  switch (action.type) {
    case "init": {
      return { ...state, error: undefined, loading: false };
    }
    case "error": {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case "request_api_infos_success": {
      return { ...state, loading: false, api_version: action.payload.version };
    }
    case "request_init": {
      return { ...state, loading: true };
    }
    case "request_password_forgotten_success": {
      return { ...state, loading: false };
    }
    case "request_logout_init": {
      return { ...state, loading: true };
    }
    case "request_logout_success": {
      return { ...state, loading: false, user: undefined, isAuthenticated: false, access_token: undefined, refresh_token: undefined };
    }
    case "request_login_init": {
      return { ...state, loading: true };
    }
    case "request_login_success": {
      let customers = action.payload.customers;
      let user = { ...action.payload.user, customers: customers };
      localStorage.setItem("user", JSON.stringify(user));
      return {
        ...state,
        loading: false,
        user,
        customers: customers,
        isAuthenticated: true,
        access_token: action.payload.token,
        refresh_token: action.payload.refresh_token,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function AuthProvider({ children }) {
  const [state, dispatch] = React.useReducer(ControlsReducer, {
    loading: false,
    error: undefined,
    isAuthenticated: undefined,
    user: undefined,
    customers: undefined,
    access_token: undefined,
    refresh_token: undefined,
    api_version: undefined,
  });
  return (
    <ControlsStateContext.Provider value={state}>
      <ControlsDispatchContext.Provider value={dispatch}>{children}</ControlsDispatchContext.Provider>
    </ControlsStateContext.Provider>
  );
}

function useStoreState() {
  const context = React.useContext(ControlsStateContext);
  if (context === undefined) {
    throw new Error("useStoreState must be used within a AuthProvider");
  }
  return context;
}

function useStoreDispatch() {
  const context = React.useContext(ControlsDispatchContext);
  if (context === undefined) {
    throw new Error("useStoreDispatch must be used within a AuthProvider");
  }
  return context;
}

export { AuthProvider, useStoreState, useStoreDispatch, get_api_infos, loginWithCredentials, auto_login, logout, password_forgotten, password_reset };
