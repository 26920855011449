import { notification } from "antd";
import { createBrowserHistory } from "history";
import React, { lazy, Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, useHistory } from "react-router-dom";
import styled from "styled-components";
import Header from "./business_components/header/Header";
import PrivateRoute from "./business_components/PrivateRoute";
import LargeSpinner from "./technical_components/LargeSpinner";
import { AdminProvider } from "./utils/stores/AdminStore";
import { useStoreState as useAuthStoreState } from "./utils/stores/AuthStore";
import {
  get_customer_permissions, useStoreDispatch as useCustomerStoreDispatch, useStoreState as useCustomerStoreState
} from "./utils/stores/CustomerStore";
import GlobalStyle from "./utils/styles/globalStyles";

const history = createBrowserHistory();

const Login = lazy(() => import("./pages/LoginPage"));
const Logout = lazy(() => import("./pages/LogoutPage"));
const AutoLogin = lazy(() => import("./pages/AutoLoginPage"));
const ForgottenPassword = lazy(() => import("./pages/ForgottenPassword"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const Home = lazy(() => import("./pages/Home"));
const Admin = lazy(() => import("./pages/admin/Admin"));
const Profile = lazy(() => import("./pages/Profile"));
const UsageContainer = lazy(() => import("./pages/usage/UsageContainer"));
const MultiUsageContainer = lazy(() => import("./pages/usage/MultiUsageContainer"));
const SwitchCustomers = lazy(() => import("./pages/SwitchCustomers"));
const ErrorPage404 = lazy(() => import("./pages/ErrorPage404"));

const AppContainerBlock = styled.div`
  box-sizing: border-box;
  min-height: 100%;
  margin: 0 auto;
  color: black;
`;

export const Content = styled.div`
  padding: 30px 170px;
  width: 1280px;
  margin: 0 auto;
  min-height: 720px;
`;

const AppContainer = ({ children }) => {
  const { customers, user } = useAuthStoreState();
  const { active_customer } = useCustomerStoreState();
  const dispatch = useCustomerStoreDispatch();
  const history_navigation = useHistory();

  useEffect(() => {
    if (customers) {
      if (!active_customer && customers.length === 1) {
        get_customer_permissions(dispatch, { customer_id: customers[0].id, user_email: user.email }).then((rep) => {
          if (!rep.success) {
            notification.destroy();
            notification["error"]({
              message: "Something failed",
              description: rep.message,
            });
          }
        });
      } else {
        if (localStorage.getItem("last_customer") && JSON.parse(localStorage.getItem("last_customer"))) {
          get_customer_permissions(dispatch, { customer_id: JSON.parse(localStorage.getItem("last_customer")).id, user_email: user.email }).then(
            (rep) => {
              if (!rep.success) {
                notification.destroy();
                notification["error"]({
                  message: "Something failed",
                  description: rep.message,
                });
              }
            }
          );
        } else {
          if (!active_customer) {
            history_navigation.push("/customers/switch");
          }
        }
      }
    }
  }, [customers]);

  return <AppContainerBlock className="container">{children}</AppContainerBlock>;
};
const App = (props) => {
  // const name = useSelector((state) => state.name);
  // const login = useSelector((state) => state.login);
  // const title = useSelector((state) => state.title);
  const { isAuthenticated } = useAuthStoreState();
  useEffect(() => {}, [isAuthenticated]);

  return (
    <Router history={history}>
      <AdminProvider>
        <AppContainer>
          <Header />
          <Suspense fallback={<LargeSpinner />}>
            <Switch>
              <Route exact path="/logout" component={Logout} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/auto-login" component={AutoLogin} />
              <Route exact path="/reset-password" component={ForgottenPassword} />
              <Route exact path="/new-password/:token" component={ResetPassword} />
              <PrivateRoute exact path="/" component={Home}></PrivateRoute>
              <PrivateRoute exact path="/customers/switch" component={SwitchCustomers}></PrivateRoute>
              <PrivateRoute exact path="/usage/customized/:customer_id" component={MultiUsageContainer}></PrivateRoute>
              <PrivateRoute path="/usage/:app_id" component={UsageContainer}></PrivateRoute>
              <PrivateRoute path="/profile" id="241" component={Profile}></PrivateRoute>
              <PrivateRoute path="/settings/admin" component={Admin}></PrivateRoute>
              <Route component={ErrorPage404} />
            </Switch>
          </Suspense>
          <GlobalStyle />
        </AppContainer>
      </AdminProvider>
    </Router>
  );
};

export default App;
